;(function (root, $) {
    var CurrentWidthClass = (function ($) {
        var _width = window.innerWidth;

        $(window).on("resize", function (event) {
            _width =  window.innerWidth;
        });

        return {
            getCurrentWidth: function () {
                return _width;
            }
        }
    })($);

    root.MyThemeApp.CurrentWidthClass = CurrentWidthClass;

})(window, jQuery);